import moment from "moment";
import React, {
  createContext,
  useEffect,
} from 'react';
import { useRecoilState } from "recoil";
import axios from 'src/utils/axios';

import { LoginToken } from "src/recoil/atoms";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const currentTime = moment()

  return moment(accessToken.expire_time) > currentTime;
};

const AuthContext = createContext({
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {
  },
});

export const AuthProvider = ({ children }) => {
  const [ token, setToken ] = useRecoilState(LoginToken)

  const login = async (username, password) => {
    const response = await axios.post('customer/signin', { username, password });
    const accessToken = response.data.data;
    setToken({
        user: accessToken,
        isAuthenticated: true,
      }
    )
  };

  const logout = () => {
    setToken({ isAuthenticated: false });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = token.user

        if (accessToken && isValidToken(accessToken)) {
          setToken({
            ...token,
            isAuthenticated: true,
          })
        } else {
          setToken({
            isAuthenticated: false,
          })
        }
      } catch (err) {
        setToken({
          isAuthenticated: false,
        })
      }
    };

    initialise();
  }, []);

  return (
    <AuthContext.Provider
      value={ {
        method: 'JWT',
        login,
        logout,
      } }
    >
      { children }
    </AuthContext.Provider>
  );
};

export default AuthContext;
