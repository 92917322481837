import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => (
  {
    root: {}
  }));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={ clsx(classes.root, className) }
      { ...rest }
    >
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
