import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestGuard from "src/components/GuestGuard";
import AuthGuard from "src/components/AuthGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={ <LoadingScreen/> }>
    <Switch>
      { routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={ i }
            path={ route.path }
            exact={ route.exact }
            render={ (props) => (
              <Guard>
                <Layout>
                  { route.routes
                    ? renderRoutes(route.routes)
                    : <Component { ...props } /> }
                </Layout>
              </Guard>
            ) }
          />
        );
      }) }
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => import('src/views/home/HomeView'))
      },
      {
        component: () => <Redirect to="/404"/>
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        path: '/',
        component: () => <Redirect to="/app/home"/>
      },
      {
        component: () => <Redirect to="/404"/>
      }
    ]

  }
];

export default routes;
