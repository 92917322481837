import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Footer from "./Footer";
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    maxWidth: '1440px',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MainLayout = ({ children }) => {
  const props = {}

  const classes = useStyles(props);
  return (
    <div className={ classes.root }>
      <TopBar/>
      <div className={ classes.wrapper }>
        <div className={ classes.contentContainer }>
          <div className={ classes.content }>
            { children }
            <Footer/>
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
