import { atom } from 'recoil'
import { localStorageEffect } from "./localStorage";

export const LoginToken = atom({
  key: "loginToken",
  default: {
    isAuthenticated: false,
    user: {
      token: "",
      expire_time: "",
      customer_name: "",
      url: "",
    }
  },
  effects_UNSTABLE: [
    localStorageEffect('login_token'),
  ]
})
