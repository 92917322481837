import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  content: {
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  divider: {
    background: "black"
  },
  textRegister: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  register: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
  },
  list: {
    height: 60,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  copyright: {
    fontSize: 12
  },
  link: {
    padding: 20
  },
  header: {
    marginBottom: 10,
  },
  boxLink: {
    marginTop: 20,
  }
}))

const Footer = ({ className, ...rest }) => {

  const classes = useStyles();

  return (
    <div
      className={ clsx(classes.root, className) }
      { ...rest }
    >
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
