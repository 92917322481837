import { DefaultValue } from 'recoil'
import crypto from 'crypto-js'

const keyAES = 'refund-social-no-bug-awesome'

export const localStorageEffect = key => ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    let bytes  = crypto.AES.decrypt(savedValue, keyAES)
    let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8))
    setSelf(decryptedData);
  }


  onSet(newValue => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(key);
    } else {
      let ciphertext = crypto.AES.encrypt(JSON.stringify(newValue), keyAES).toString();
      localStorage.setItem(key, ciphertext);
    }
  });
};
